import React,{useEffect, useState} from 'react';
import './FullScreenPopup.css'; // We'll define the CSS styles later
import { useHidePopup, useIsVisablePopup } from '../../utils/popupUtils';
import {calculateUSDNeeded, calculateTokenOutput , 
    calculateTokensForBNB, calculateBNBNeeded, isValidNumber, truncateMiddle,
    CONST, useWallet,
    useDirectTransfer} from '../../services/wallet-service';
import configs from '../../config.main.json';
import { useDonateAmount } from '../../utils/donateUtils';
import { useBnbPrice,useEthPrice } from '../../utils/priceUtils';
import Decimal from 'decimal.js';


const FullScreenPopup = ({ children }) => {
    const currencies = [
        { text: 'ETH', imageSrc: '/img/shilbaprime/icon@eth.svg', curr:CONST.ETH, network:CONST.ETH },
        { text: 'USDT', imageSrc: '/img/shilbaprime/icon@erc20.svg', curr:CONST.ERC20, network:CONST.ETH },
        { text: 'BNB', imageSrc: '/img/shilbaprime/icon@bnb.svg', curr:CONST.BNB, network:CONST.BSC },
        { text: 'USDT', imageSrc: '/img/shilbaprime/icon@bep20.svg', curr:CONST.USDT, network:CONST.BSC },
        // { text: 'SOLANA', imageSrc: '/img/icon@solana.svg', curr:CONST.SOL, network:CONST.SOL },
    ];
    const bnbPrice = useBnbPrice();
    const ethPrice = useEthPrice();
    const donateAmount = useDonateAmount()
    // const wallet=useWallet(network, configs);
    const directTransfer = useDirectTransfer(configs)
      
    const isVisible = useIsVisablePopup()
    const hidePopup = useHidePopup();

    const [isClicked, setIsClicked] = useState(false);
    const coolDownTime = 300; // milliseconds
  

    useEffect(() => {
        if (isClicked) {
          const timeoutId = setTimeout(() => setIsClicked(false), coolDownTime);
          return () => clearTimeout(timeoutId);
        }
    }, [isClicked]);


    if (!isVisible) return null;


   

    // Function to multiply two numbers and return the result as a string with two decimal places
  const  multiplyAndFormat = (a, b) =>{
    const result = new Decimal(a).mul(new Decimal(b));
    return result.toFixed(2);
  }

  // Function to multiply two numbers and return the result as a string with two decimal places
  const  divAndFormat = (a, b) =>{
    const result = new Decimal(a).div(new Decimal(b));
    return result.toFixed(4);
  }

    const handleBuyTokenClick = async (curr) => {
        debugger
        if (!isClicked) {
          setIsClicked(true);
          // Your button click logic here
          if (curr === CONST.ETH) {
            const ethAmount = divAndFormat(donateAmount,ethPrice)
            await directTransfer.directBuyTokens(ethAmount, CONST.ETH)
          }
          if (curr === CONST.BNB) {
            const bnbAmount = divAndFormat(donateAmount,bnbPrice)
            await directTransfer.directBuyTokens(bnbAmount, CONST.BSC)
          }
          if(curr === CONST.ERC20){
            await directTransfer.directBuyTokensUSDT(donateAmount, CONST.ETH)
          }
          if(curr === CONST.USDT){
            await directTransfer.directBuyTokensUSDT(donateAmount, CONST.BSC)

          }
        }
    
      }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-button" onClick={hidePopup}>×</span>
        <div className="button-container">
            <button 
                onClick={() => handleBuyTokenClick(CONST.ETH)}
                // className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.ETH ? 'selected' : ''}`}
                >
                <img height="24" alt="" src="./img/shilbaprime/icon@eth.svg" />
                <span className="px-2 font-14">ETH</span>
            </button>

            <button  
                onClick={() => handleBuyTokenClick(CONST.ERC20)}

                // className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.ERC20 ? 'selected' : ''}`}
                >
                <img height="24" alt="" src="./img/shilbaprime/icon@erc20.svg" />
                <span className="px-2 font-14">USDT</span>
            </button>
            <button 
                onClick={() => handleBuyTokenClick(CONST.BNB)}

                // className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.BNB ? 'selected' : ''}`}
                >
                <img height="24" alt="" src="./img/shilbaprime/icon@bnb.svg" />
                <span className="px-2 font-14">BNB</span>
            </button>
            <button 
                onClick={() => handleBuyTokenClick(CONST.USDT)}

                // className={`btn btn-wallet btn-light font-14 text-uppercase d-flex align-items-center justify-content-center ${selectedCurr?.curr === CONST.USDT ? 'selected' : ''}`}
                >
                <img src="./img/shilbaprime/icon@bep20.svg" height="24" alt="" />
                <span className="px-2 font-14">USDT</span>
            </button>
        </div>
      </div>
    </div>
  );
};

export default FullScreenPopup;
