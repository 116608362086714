import React, { useState, useEffect } from 'react';

export const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial viewport width
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);


  return (
    <footer className="footer">
    <div className="footer-content">
      <p>Paid for by Donald J Trump for President 2024, Inc.</p>
      <ul className="footer-links">
        <li><a href="/privacy-policy">Privacy Policy</a></li>
        <li><a href="/terms-conditions">Terms & Conditions</a></li>
        <li><a href="/press">Press</a></li>
        <li><a href="/powered-by-nucleus">Powered by Nucleus</a></li>
      </ul>
    </div>
  </footer>
  );
};
