// languageDuck.js
// Redux Duck for managing language
import { isValidNumber } from "../services/wallet-service";
// Action types
const SET_DONATE_PACK = 'donate/SET_PACK'; // New action type for setting language
const TOGGLE_DONATE_MAX = 'donate/TOGGLE_DONATE_MAX'; // New action type for setting language

export const packages = [20.24, 45, 470, 2024, 4547];

// Determine the initial language
const determineInitialAmount = () => {
    debugger
    const amount = localStorage.getItem('donate_amount');
    if (amount && isValidNumber(amount)) {

      return parseFloat(amount);
    }
    return packages[packages.length - 1]
};

// Initial state
const initialState = {
    amount: determineInitialAmount(),
    isMax: false
};

// Action creators
export const setDonatePack = (amount) => {
    debugger
    // Validate language against the valid range
    if(amount === "" || isValidNumber(amount)){
        localStorage.setItem('donate_amount', amount);
        
        return {
            type: SET_DONATE_PACK,
            payload: amount,
        };
    }
    return { type: 'INVALID_ACTION' }; // or throw an error
};

export const toggleDonateMax = () => {
    return {
        type: SET_DONATE_PACK
    };
}

// Selectors

export const selectDonateAmountState = state => state.donate?.amount||packages[0];


// Reducer
const donateReducer = (state = initialState, action) => {
  switch (action.type) {
        
    case SET_DONATE_PACK:
        return { ...state, amount: action.payload };

    case SET_DONATE_PACK:
        return { ...state, isMax: !state.isMax };
    default:
        return state;
  }
};

export default donateReducer;
