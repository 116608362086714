import React from "react";
import { useI18nSection } from "../../utils/languageUtils";


export const Featured = () => {
  const sectionText = useI18nSection('features')

  return (
    <main role="main" id="main">
      <section className="main-ban">
        <div className="container justify-content-center mg-top">
          <div className="content color-white">
            <h1 className="sirName mb-4" >DONALD J. TRUMP</h1>
            <h2 className="title-lrg-1 mb-4 color-white">Now accepting crypto</h2>
            <p className="mb-3 color-white">Demonstrating President Trump’s success as a champion of American freedom and innovation, we proudly offer you a chance to contribute to the campaign with cryptocurrency. Saving our nation from Biden’s failures requires your support. As Biden piles regulations and red tape on all of us, President Trump stands ready to embrace new technologies that will Make America Great Again.</p>
            <div className="crypto-icons">
              <img src="img/wienerdog/Bitcoin-Logo.webp" />
              <img src="img/wienerdog/eth-logo.webp" />
              <img src="img/wienerdog/Solana_logo.webp" />
              <img src="img/wienerdog/usd-coin-usdc-logo.webp" />
              <img src="img/wienerdog/6675833.webp" />
              <img src="img/wienerdog/dogecoin-logo.webp" />
              <img src="img/wienerdog/glass-crypto.webp" />
              <img src="img/wienerdog/metamask-logo.webp" />
            </div>
            <a className="btn-t color-white" href="#crypt-form">Donate Now </a>
          </div>
        </div>
      </section>
      
    </main>
  );
};
