import React from "react";
import "./Faqs.css";

export const Faqs = () => {
    return (
        <div id="faqs">
            <div class="faq-container">
                <div class="container position-relative">
                    <div class="row justify-content-center">
                        <div class="faq-wrapper">
                            <h2 translate="" class="heading font-50 mb-5 text-center text-primary text-uppercase">faq</h2>
                        </div>
                        <div class="col-12 col-md-6">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
