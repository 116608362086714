import axios from 'axios';


export const FETCH_DATA_REQUEST = 'BNB/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'BNB/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'BNB/FETCH_DATA_FAILURE';


export const fetchBnbPriceRequest = () => ({
    type: FETCH_DATA_REQUEST,
  });
  
  export const fetchBnbPriceSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
  });
  
  export const fetchBnbPriceFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
  });
  
  export const fetchBnbPrice = () => {
    return (dispatch) => {
      dispatch(fetchBnbPriceRequest());
      axios.get('https://www.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
        .then(response => {
          dispatch(fetchBnbPriceSuccess(response.data));
        })
        .catch(error => {
          dispatch(fetchBnbPriceFailure(error.message));
        });
    };
  };
  

// // Selectors
// export const selectI18nSection = (sectionKey) => (state) => {
//     const language = state.language.language;
//     return i18nData[language][sectionKey] || {};
// };
  
export const selectBnbPriceState = state => state.bnbPrice?.bnbPrice||0;



const initialState = {
    loading: false,
    bnbPrice: 0,
    error: '',
};

const bnbPriceProducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DATA_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DATA_SUCCESS:
        return {
          loading: false,
          bnbPrice: action.payload.price,
          error: '',
        };
      case FETCH_DATA_FAILURE:
        return {
          loading: false,
          bnbPrice: 0,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default bnbPriceProducer;
  
  