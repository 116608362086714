import { useSelector, useDispatch } from 'react-redux';
import { selectBnbPriceState } from '../ducks/bnbPriceDuck';
import { selectEthPriceState } from '../ducks/ethPriceDuck';

// Custom hook to retrieve the current language
export const useBnbPrice = () => {
    return useSelector(selectBnbPriceState);
};
// Custom hook to retrieve the current language
export const useEthPrice = () => {
    return useSelector(selectEthPriceState);
};