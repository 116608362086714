import { useSelector, useDispatch } from 'react-redux';

import { selectDonateAmountState, setDonatePack, toggleDonateMax } from '../ducks/donatePackageDuck';


// Custom hook to retrieve the current language
export const useDonateAmount = () => {
    return useSelector(selectDonateAmountState);
  };
// Custom hook to retrieve the current language
// export const useMaxDonaote = () => {
//     return useSelector(selectDonateAmountState);
//   };
  // Custom hook for setting speed
export const useSetDonatePack = () => {
    const dispatch = useDispatch();
    return (amount) => {
      dispatch(setDonatePack(amount));
    };
  };

export const useToggleDonateMax = () => {
    const dispatch = useDispatch();
    return () => {
      dispatch(toggleDonateMax());
    };
  };

