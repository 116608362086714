import React, { useState, useEffect } from "react";
import { useI18nSection, useLanguage, useSetLanguage } from "../../utils/languageUtils";

export const Navigation = () => {
  const [isMobile, setIsMobile] = useState(false);

  const currentLanguage = useLanguage()

  const sectionText = useI18nSection('nav')
  const setLanguage = useSetLanguage()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Adjust the breakpoint as needed
    };

    handleResize(); // Check initial viewport width
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  const languageOptions = {
    en: {
      flag: "fi fi-gb width-size",
      name: "English",
      selectLanguage: "",
    },
    zh: {
      flag: "fi fi-cn width-size",
      name: "中文",
      selectLanguage: "",
    },
    es: {
      flag: "fi fi-es width-size",
      name: "Español",
      selectLanguage: "",
    },
    jp: {
      flag: "fi fi-jp width-size",
      name: "日本語",
      selectLanguage: "",
    },
    vi: {
      flag: "fi fi-vn width-size",
      name: "Tiếng Việt",
      selectLanguage: "",
    },
    de: {
      flag: "fi fi-de width-size",
      name: "Deutsch",
      selectLanguage: "",
    },
  };

  const handleChangeLanguage = (language) => {
    setLanguage(language);
  };

  const scrollToBuySection = () => {
    // Find the target section to scroll to
    const section = document.getElementById('intro');
    if (!section) {
      window.location = "/"
      return
    }
    // Scroll to the section
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div >
        <div className="navbar-header">
          <a className="navbar-brand page-scroll" href="/" style={{ float: 'none' }}>
            {/* <img src="/img/wienerdog/trump-logo-pop.webp" width={"150"} height={"40"} style={{ "display": "inline" }} />
             */}
            <svg width="200px" height="64px" viewBox="0 0 200 64" >
              <g>
                <polygon className="trump" points="23.3,19.8 31.5,19.8 31.5,39.3 39.2,39.3 39.2,19.8 47.5,19.8 47.5,13.5 23.3,13.5 "></polygon>
                <path className="trump" d="M77.8,22.3L77.8,22.3c0-2.6-0.8-4.6-2.4-6.1c-1.9-1.7-4.8-2.8-9-2.8h-13v25.7H61v-7.8h3.4l5.6,7.8h8.8l-6.6-9 C75.6,28.9,77.8,26.3,77.8,22.3z M70.2,22.8c0,1.9-1.5,3.1-4.1,3.1H61v-6.2h5C68.5,19.7,70.2,20.7,70.2,22.8L70.2,22.8z"></path>
                <path className="trump" d="M101.8,28c0,3.6-2,5.3-5.1,5.3c-3.1,0-5.1-1.8-5.1-5.5V13.5h-7.7V28c0,8.1,4.9,11.8,12.7,11.8 c7.8,0,12.9-3.7,12.9-11.9V13.5h-7.7V28z"></path>
                <polygon className="trump" points="131.2,23.5 124.6,13.5 116.6,13.5 116.6,39.3 124.1,39.3 124.1,24.5 131.1,34.5 131.2,34.5 138.3,24.5 138.3,39.3 145.9,39.3 145.9,13.5 137.8,13.5 "></polygon>
                <path className="trump" d="M165.1,13.5h-11.9v25.7h7.6v-7.4h3.8c6.8,0,11.8-3.2,11.8-9.3v-0.1C176.6,16.9,172.1,13.5,165.1,13.5z M169,22.9c0,2-1.7,3.4-4.4,3.4h-3.7v-6.8h3.6C167.3,19.5,169,20.7,169,22.9L169,22.9z"></path>
              </g>
              <g>
                <polygon className="stars" points="100.9,2.9 99.9,0 99,2.9 95.9,2.9 98.4,4.7 97.4,7.6 99.9,5.8 102.4,7.6 101.5,4.7 104,2.9 "></polygon>
                <polygon className="stars" points="79.7,2.9 78.7,0 77.8,2.9 74.7,2.9 77.2,4.7 76.2,7.6 78.7,5.8 81.2,7.6 80.3,4.7 82.8,2.9 "></polygon>
                <polygon className="stars" points="90.3,2.9 89.3,0 88.4,2.9 85.3,2.9 87.8,4.7 86.8,7.6 89.3,5.8 91.8,7.6 90.9,4.7 93.4,2.9 "></polygon>
                <polygon className="stars" points="125.2,2.9 122.1,2.9 121.1,0 120.2,2.9 117.1,2.9 119.6,4.7 118.6,7.6 121.1,5.8 123.6,7.6 122.7,4.7 "></polygon>
                <polygon className="stars" points="111.5,2.9 110.5,0 109.6,2.9 106.5,2.9 109,4.7 108,7.6 110.5,5.8 113,7.6 112.1,4.7 114.6,2.9 "></polygon>
              </g>
              <g>
                <polygon className="border" points="134.3,2.9 134.3,4.3 198.6,4.3 198.6,59.5 126.2,59.5 126.2,60.9 200,60.9 200,2.9 "></polygon>
                <polygon className="border" points="1.4,4.3 65.7,4.3 65.7,2.9 0,2.9 0,60.9 73.8,60.9 73.8,59.5 1.4,59.5 "></polygon>
              </g>
              <g>
                <path className="year" d="M88.4,60.8c1-0.6,1.6-1.3,1.6-2.5v0c0-1.5-1.3-2.6-3.2-2.6c-1.7,0-2.6,0.6-3.5,1.8l1.8,1.5 c0.7-0.7,1.1-1,1.5-1c0.5,0,0.7,0.3,0.7,0.6c0,0.3-0.2,0.6-0.7,1l-3.3,2.4v1.9h6.8v-2.1H87L88.4,60.8z"></path>
                <path className="year" d="M95.7,55.7c-2.4,0-3.9,2-3.9,4.2v0c0,2.2,1.4,4.1,3.9,4.1c2.4,0,3.9-2,3.9-4.2v0 C99.6,57.6,98.1,55.7,95.7,55.7z M96.9,59.9c0,1.1-0.5,1.8-1.2,1.8c-0.7,0-1.2-0.8-1.2-1.8v0c0-1.1,0.5-1.8,1.2-1.8 C96.4,58,96.9,58.8,96.9,59.9L96.9,59.9z"></path>
                <path className="year" d="M106.2,60.8c1-0.6,1.6-1.3,1.6-2.5v0c0-1.5-1.3-2.6-3.2-2.6c-1.7,0-2.6,0.6-3.5,1.8l1.8,1.5 c0.7-0.7,1.1-1,1.5-1s0.7,0.3,0.7,0.6c0,0.3-0.2,0.6-0.7,1l-3.3,2.4v1.9h6.8v-2.1h-3.1L106.2,60.8z"></path>
                <path className="year" d="M115.9,60.6v-4.8h-3l-3.9,4.8l0.4,1.9h3.9v1.4h2.6v-1.4h1v-1.9H115.9z M113.3,60.6h-1.7l1.7-2.1V60.6z"></path>
              </g>
              <g>
                <rect x="167.2" y="48.5" className="slogan" width="1.5" height="1.3"></rect>
                <polygon className="slogan" points="34.3,46.6 33,44.6 31.5,44.6 31.5,49.8 32.9,49.8 32.9,46.8 34.3,48.8 34.3,48.8 35.6,46.8 35.6,49.8 37.1,49.8 37.1,44.6 35.5,44.6 "></polygon>
                <path className="slogan" d="M40.6,44.5l-2.2,5.2h1.5l0.4-0.9h2l0.4,0.9h1.6L42,44.5H40.6z M40.7,47.7l0.6-1.5l0.6,1.5H40.7z"></path>
                <polygon className="slogan" points="50.4,44.6 48.7,44.6 46.9,46.7 46.9,44.6 45.4,44.6 45.4,49.8 46.9,49.8 46.9,48.4 47.4,47.8 48.8,49.8 50.5,49.8 48.4,46.8 "></polygon>
                <polygon className="slogan" points="53.1,47.7 55.6,47.7 55.6,46.6 53.1,46.6 53.1,45.8 55.9,45.8 55.9,44.6 51.7,44.6 51.7,49.8 55.9,49.8 55.9,48.6 53.1,48.6 "></polygon>
                <path className="slogan" d="M62,44.5l-2.2,5.2h1.5l0.4-0.9h2l0.4,0.9h1.6l-2.2-5.2H62z M62.1,47.7l0.6-1.5l0.6,1.5H62.1z"></path>
                <polygon className="slogan" points="69.7,46.6 68.4,44.6 66.9,44.6 66.9,49.8 68.3,49.8 68.3,46.8 69.7,48.8 69.7,48.8 71,46.8 71,49.8 72.5,49.8 72.5,44.6 70.9,44.6 "></polygon>
                <polygon className="slogan" points="75.6,47.7 78.1,47.7 78.1,46.6 75.6,46.6 75.6,45.8 78.4,45.8 78.4,44.6 74.1,44.6 74.1,49.8 78.4,49.8 78.4,48.6 75.6,48.6 "></polygon>
                <path className="slogan" d="M84.5,46.4L84.5,46.4c0-0.5-0.2-0.9-0.5-1.2c-0.4-0.3-0.9-0.6-1.7-0.6h-2.5v5.2h1.5v-1.6H82l1.1,1.6h1.7 L83.5,48C84.1,47.7,84.5,47.2,84.5,46.4z M83.1,46.5c0,0.4-0.3,0.6-0.8,0.6h-1v-1.3h1C82.8,45.8,83.1,46,83.1,46.5L83.1,46.5z"></path>
                <rect x="86.1" y="44.6" className="slogan" width="1.5" height="5.2"></rect>
                <path className="slogan" d="M91.8,48.6c-0.7,0-1.2-0.6-1.2-1.4v0c0-0.8,0.5-1.4,1.2-1.4c0.5,0,0.8,0.2,1.1,0.6l1.1-0.8 c-0.5-0.6-1.2-1.1-2.2-1.1c-1.6,0-2.8,1.2-2.8,2.7v0c0,1.6,1.2,2.7,2.7,2.7c1.2,0,1.8-0.5,2.3-1.2L93,47.9 C92.6,48.3,92.3,48.6,91.8,48.6z"></path>
                <path className="slogan" d="M97.2,44.5L95,49.8h1.5l0.4-0.9h2l0.4,0.9h1.6l-2.2-5.2H97.2z M97.3,47.7l0.6-1.5l0.6,1.5H97.3z"></path>
                <path className="slogan" d="M107.4,47.8h1v0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.8,0-1.4-0.6-1.4-1.4v0c0-0.8,0.6-1.4,1.3-1.4 c0.5,0,0.9,0.2,1.3,0.5l0.9-1c-0.6-0.5-1.2-0.7-2.1-0.7c-1.6,0-2.8,1.2-2.8,2.7v0c0,1.6,1.2,2.7,2.8,2.7c1,0,1.7-0.3,2.3-0.8v-2.3 h-2.4V47.8z"></path>
                <path className="slogan" d="M116,46.4L116,46.4c0-0.5-0.2-0.9-0.5-1.2c-0.4-0.3-0.9-0.6-1.7-0.6h-2.5v5.2h1.5v-1.6h0.6l1.1,1.6h1.7L115,48 C115.6,47.7,116,47.2,116,46.4z M114.6,46.5c0,0.4-0.3,0.6-0.8,0.6h-1v-1.3h1C114.3,45.8,114.6,46,114.6,46.5L114.6,46.5z"></path>
                <polygon className="slogan" points="119,47.7 121.5,47.7 121.5,46.6 119,46.6 119,45.8 121.7,45.8 121.7,44.6 117.5,44.6 117.5,49.8 121.8,49.8 121.8,48.6 119,48.6 "></polygon>
                <path className="slogan" d="M125.1,44.5l-2.2,5.2h1.5l0.4-0.9h2l0.4,0.9h1.6l-2.2-5.2H125.1z M125.2,47.7l0.6-1.5l0.6,1.5H125.2z"></path>
                <polygon className="slogan" points="129,45.8 130.6,45.8 130.6,49.8 132,49.8 132,45.8 133.6,45.8 133.6,44.6 129,44.6 "></polygon>
                <path className="slogan" d="M139.6,44.5l-2.2,5.2h1.5l0.4-0.9h2l0.4,0.9h1.6l-2.2-5.2H139.6z M139.7,47.7l0.6-1.5l0.6,1.5H139.7z"></path>
                <path className="slogan" d="M146.7,47.8h1v0.6c-0.2,0.1-0.5,0.2-0.8,0.2c-0.8,0-1.4-0.6-1.4-1.4v0c0-0.8,0.6-1.4,1.3-1.4 c0.5,0,0.9,0.2,1.3,0.5l0.9-1c-0.6-0.5-1.2-0.7-2.1-0.7c-1.6,0-2.8,1.2-2.8,2.7v0c0,1.6,1.2,2.7,2.8,2.7c1,0,1.7-0.3,2.3-0.8v-2.3 h-2.4V47.8z"></path>
                <path className="slogan" d="M152.5,44.5l-2.2,5.2h1.5l0.4-0.9h2l0.4,0.9h1.6l-2.2-5.2H152.5z M152.6,47.7l0.6-1.5l0.6,1.5H152.6z"></path>
                <rect x="157.4" y="44.6" className="slogan" width="1.5" height="5.2"></rect>
                <polygon className="slogan" points="164,47.3 161.9,44.6 160.5,44.6 160.5,49.8 162,49.8 162,46.9 164.2,49.8 165.5,49.8 165.5,44.6 164,44.6 "></polygon>
                <polygon className="slogan" points="167.1,44.6 167.1,45.2 167.5,48.1 168.3,48.1 168.7,45.2 168.7,44.6 "></polygon>
              </g>
            </svg>
          </a>{" "}
          <button className="navbar-toggle collapsed btn-buynow" style={{
            cursor: 'pointer',
            borderRadius: '8px',
            border: '1px solid #171717',
            textTransform: 'capitalize',
            color: "#2a2a2a",
          }}
            onClick={scrollToBuySection}>
            {sectionText.buyNow}
          </button>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="https://www.donaldjtrump.com/mission" className="page-scroll">
                MISSION
              </a>
            </li>
            <li>
              <a href="https://www.donaldjtrump.com/news" className="page-scroll">
                NEWS
              </a>
            </li>
            <li>
              <a href="https://www.donaldjtrump.com/agenda47" className="page-scroll">
              Agenda47
              </a>
            </li>
            <li>
              <a href="https://www.donaldjtrump.com/issues" className="page-scroll">
              Issues
              </a>
            </li>
            <li>
              <a href="https://www.donaldjtrump.com/events" className="page-scroll">
              EVENTS
              </a>
            </li>
            <li>
              <a href="https://www.donaldjtrump.com/join" className="page-scroll">
              JOIN
              </a>
            </li>
            {isMobile &&
              <li>
                <button
                  className="dropdown-toggle btn btn-default btn-english"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: '80px', overflow: 'hidden', backgroundColor: '#af43' }}
                >
                  <span className={languageOptions[currentLanguage].flag}></span>
                  {languageOptions[currentLanguage].selectLanguage}{" "}
                  <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                  {Object.keys(languageOptions).map((language) => (
                    <li key={language}>
                      <a
                        // href="#"
                        onClick={() => handleChangeLanguage(language)}
                      >
                        <span className={languageOptions[language].flag}></span>
                        {languageOptions[language].name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            }
          </ul>

          {!isMobile &&
            <div className="buy-and-contact">
              <a href="https://secure.winred.com/trump-national-committee-jfc/lp-website-contribute-button?_gl=1*1jzemyx*_gcl_au*Mjc0NTQ5MTEwLjE3MTYzNDgxMzM.&_ga=2.177261223.340798434.1716348133-1801882648.1716348133" className="btn btn-buynow" onClick={scrollToBuySection}>
                Contribute
              </a>
              <a href="https://secure.winred.com/trump-national-committee-jfc/storefront?_gl=1*1jzemyx*_gcl_au*Mjc0NTQ5MTEwLjE3MTYzNDgxMzM.&_ga=2.177261223.340798434.1716348133-1801882648.1716348133" className="level-1-link btn btn-blue btn-filled" onClick={scrollToBuySection}>
                SHOP
              </a>
            </div>
          }
        </div>
      </div>
    </nav>
  );
};
