import React, { useState, useEffect } from "react";
import { Navigation } from "../components/Navigator/navigation";
import { BrandsCarousel } from "../components/brands-carousel/BrandsCarousel";
import { Footer } from "../components/Footer/footer";

const Staking = () => {
 
  return (
    <div id="container">
        <Navigation />
        <BrandsCarousel />
        <Footer/>
    </div>
  );
};

export default Staking;
