// actionTypes.js
export const SHOW_POPUP = 'fullscreen/SHOW_POPUP';
export const HIDE_POPUP = 'fullscreen/HIDE_POPUP';


export const showPopup = () => {
  localStorage.setItem('visable_popup', true);

  return{ type: SHOW_POPUP}
};
export const hidePopup = () => {
  localStorage.setItem('visable_popup', false);

  return{ type: HIDE_POPUP}
};


export const selectPopupState = state => state.fullscreenPopup.isVisible || false;

// Determine the initial language
const determineInitState = () => {
  debugger
  const visable = localStorage.getItem('visable_popup');
  if(visable === "true"){
    return true
  }
  return false
};


const initialState = {
    isVisible: determineInitState(),
  };
  
  const popupReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_POPUP:
        return {
          ...state,
          isVisible: true,
        };
      case HIDE_POPUP:
        return {
          ...state,
          isVisible: false,
        };
      default:
        return state;
    }
  };
  
  export default popupReducer;
  