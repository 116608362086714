import React, { useState, useEffect, useMemo } from 'react';
import { Navbar, Nav, Container, Row, Col, Card } from 'react-bootstrap';
import {
  calculateUSDNeeded, calculateTokenOutput,
  calculateTokensForBNB, calculateBNBNeeded, isValidNumber, truncateMiddle,
  CONST, useWallet
} from '../services/wallet-service';
import configs from '../config.main.json';
import { useCountdown } from '../services/utils';
import { useTokenInfo, getUserPurchaseInfo } from '../services/token-service';
import { useI18nSection } from "../utils/languageUtils";
import Form from './form/form';
import { fetchBnbPrice } from '../ducks/bnbPriceDuck';
import { fetchEthPrice } from '../ducks/ethPriceDuck';
import { useDispatch, useSelector } from 'react-redux';
import { useBnbPrice } from '../utils/priceUtils';
import { useEthPrice } from '../utils/priceUtils';
import FullScreenPopup from './fullscreen-popup/FullScreenPopup';
import { packages } from '../ducks/donatePackageDuck';
import { useDonateAmount, useSetDonatePack, useToggleDonateMax } from '../utils/donateUtils';
export const Header = () => {
  const dispatch = useDispatch();

  const bnbPrice = useBnbPrice();
  const ethPrice = useEthPrice();
  const donateAmount = useDonateAmount()
  const setDonatePack = useSetDonatePack() 
  const toggleMax = useToggleDonateMax()

  const isSelectedPack = packages.indexOf(donateAmount) > -1
  // // const numbers = [20.24, 45, 470, 2024, 4547];
  // const [activeIndex, setActiveIndex] = useState(null);

  // const handleDivClick = (index) => {
  //   setActiveIndex(index);
  // };

  // const currencies = [
  //   { text: 'ETH', imageSrc: '/img/shilbaprime/icon@eth.svg', curr: CONST.ETH, network: CONST.ETH },
  //   { text: 'USDT', imageSrc: '/img/shilbaprime/icon@erc20.svg', curr: CONST.ERC20, network: CONST.ETH },
  //   { text: 'BNB', imageSrc: '/img/shilbaprime/icon@bnb.svg', curr: CONST.BNB, network: CONST.BSC },
  //   { text: 'USDT', imageSrc: '/img/shilbaprime/icon@bep20.svg', curr: CONST.USDT, network: CONST.BSC },
  //   // { text: 'SOLANA', imageSrc: '/img/icon@solana.svg', curr:CONST.SOL, network:CONST.SOL },
  // ];

  // const [network, setNetwork] = useState("")
  // const [networkPrice, setNetworkPrice] = useState(0);
  // const [useTotalBought, setUseTotalBought] = useState();

  // const [selectedCurr, setSelectedCurr] = useState();

  // const endSaleTime = useMemo(() => new Date(configs['endSaleTime']).getTime(), []); // Memoize endSaleTime
  // const { days, hours, minutes, seconds } = useCountdown(endSaleTime);
  // const tokenInfo = useTokenInfo(configs)
  // const wallet = useWallet(network, configs);
  // const [tokenInput, setTokenInput] = useState('');


  // const [isClicked, setIsClicked] = useState(false);
  // const coolDownTime = 300; // milliseconds

  // const sectionText = useI18nSection('buyForm')

  // useEffect(() => {
  //   if (isClicked) {
  //     const timeoutId = setTimeout(() => setIsClicked(false), coolDownTime);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [isClicked]);

  // useEffect(() => {
  //   if (!wallet) {
  //     return;
  //   }

  //   const loadPurchaseInfo = async () => {
  //     try {

  //       const info = await getUserPurchaseInfo(configs, wallet.currentAddress)
  //       if (info) {
  //         setUseTotalBought(info)
  //       }
  //     }
  //     catch (err) {

  //     }
  //   }
  //   loadPurchaseInfo()
  // }, [wallet.currentAddress, configs]); // Empty dependency array ensures this effect runs only once



  // useEffect(() => {
  //   // Retrieve the stored value from localStorage
  //   const currIdxVal = localStorage.getItem('CurrIdx');

  //   // Update the state with the stored value
  //   if (currIdxVal !== null) {
  //     const curr = currencies[currIdxVal]
  //     setSelectedCurr(curr);
  //     setNetwork(curr.network)
  //   }
  //   else {
  //     setSelectedCurr(currencies[0]);
  //     setNetwork(currencies[0].network)
  //   }

  // }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    dispatch(fetchBnbPrice());
    dispatch(fetchEthPrice());
  }, [dispatch]);





  // const handleTokenInputChange = (event) => {
  //   const { value } = event.target;
  //   if (value === "") {
  //     setTokenInput(value);
  //     setCurrencyInput("")
  //     return
  //   }
  //   if (!tokenInfo || !tokenInfo?.tokenPriceInUsdt) {
  //     return
  //   }
  //   // Regular expression to allow only numeric and float values
  //   if (/^\d*\.?\d*$/.test(value) && isValidNumber(value)) {
  //     setTokenInput(value);
  //     if (selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH) {
  //       setCurrencyInput(calculateBNBNeeded(value, networkPrice, tokenInfo?.tokenPriceInUsdt))
  //     }
  //     else {
  //       setCurrencyInput(calculateUSDNeeded(value, tokenInfo?.tokenPriceInUsdt))
  //     }
  //   }
  // };

  // const [currencyInput, setCurrencyInput] = useState('');

  const handleCurrencyInputChange = (event) => {
    const { value } = event.target;
    // if (!tokenInfo || !tokenInfo?.tokenPriceInUsdt) {
    //   return
    // }
    // Regular expression to allow only numeric and float values
    if (value === "" || (/^[0-9]*[.]?[0-9]*$/.test(value) && isValidNumber(value))) {
      setDonatePack(value)
      // setCurrencyInput(value);
      // if (selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH) {
      //   setTokenInput(calculateTokensForBNB(value, networkPrice, tokenInfo?.tokenPriceInUsdt))
      // }
      // else {
      //   setTokenInput(calculateTokenOutput(value, tokenInfo?.tokenPriceInUsdt))
      // }
    }
  };

  // const handleBuyTokenClick = async () => {
  //   if (!isClicked) {
  //     setIsClicked(true);
  //     // Your button click logic here
  //   }

  //   if (selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH) {
  //     await wallet.buyTokens(currencyInput)
  //   }
  //   else {
  //     await wallet.buyTokensUSDT(currencyInput);
  //   }
  // }


  // const handleBuyMaxClick = async () => {
  //   if (!isClicked) {
  //     setIsClicked(true);
  //     // Your button click logic here
  //   }

  //   if (selectedCurr.curr === CONST.BNB || selectedCurr.curr === CONST.ETH) {
  //     const maxVal = await wallet.getMaxAmount()
  //     await wallet.buyTokens(maxVal)
  //   }
  //   else {
  //     const maxUsdt = await wallet.getMaxUSDT()
  //     await wallet.buyTokensUSDT(maxUsdt);
  //   }
  // }

  // const handleKeyPressCurr = (event) => {
  //   // Allow the dot character (.) only if it doesn't already exist in the input value
  //   if (event.key === '.' && currencyInput.includes('.')) {
  //     event.preventDefault();
  //   }
  // };

  // const handleKeyPressToken = (event) => {
  //   // Allow the dot character (.) only if it doesn't already exist in the input value
  //   if (event.key === '.' && tokenInput.includes('.')) {
  //     event.preventDefault();
  //   }
  // };


  // const handleSwitchOption = (idx) => {
  //   const curr = currencies[idx]
  //   setSelectedCurr(curr)
  //   setNetwork(curr.network)
  //   localStorage.setItem('CurrIdx', idx);

  //   setTokenInput('')
  //   setCurrencyInput('')

  // };




  return (
    <div id="crypt-form" className='intro'>
      {/* <div className="container"> */}
      <div style={{ paddingLeft: '12px', paddingRight: '12px', height: '100%', width: '100%', display: 'flex' }}>
        <div className="bannerSec">
          <Row style={{ flex: 1 }}>
            <Col md={3} sm={12}>
              <div style={{ width: '100%', height: '100%', maxWidth: '550px', margin: '0px auto' }}>
                <img className='crypto-image' src="img/wienerdog/djt-crypto-phone.webp" />
              </div>
            </Col>
            <Col md={9} sm={12} style={{ display: 'flex', flexDirection: "row", alignContent: 'center' }}>
              <div className="walletBox" id='walletBox'>
                <div className="w-100 d-flex flex-column align-items-center justify-content-start text-center  ">
                  <h4 className="text-white text-center font-18 fw-bold">Start donating.</h4>
                </div>
                  <div className="swapArea">
                    <div className="amount-selector">
                      {packages.map((number, index) => (
                        <div
                          key={index}
                          className={`amt clickable-div ${number === donateAmount ? 'active' : ''}`}
                          onClick={() => setDonatePack(number)}
                        >
                          ${number}
                        </div>
                      ))}
                      <input 
                        
                        className={`amt-ct ${!isSelectedPack ? 'active' : ''}`}
                        placeholder="custom" type="number" value={!isSelectedPack ? donateAmount : ""} onChange={handleCurrencyInputChange} />
                    </div>
                    <Form />
                  </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ margin : '20 113px' }}>
        <div className="container p-policy">
          <p>
            By providing your phone number, you are consenting to receive calls and
            recurring SMS/MMS messages, including autodialed and automated calls and
            texts, to that number from each of the participating committees in Trump
            National Committee JFC, Inc. ("TNC"), a joint fundraising committee
            composed of and authorized by Donald J. Trump for President 2024, Inc.
            ("DJTFP"), the principal campaign committee of Donald J. Trump, and the
            Republican National Committee ("RNC"). Msg &amp; data rates may apply. Terms
            &amp; conditions/privacy policy apply.
            <a href="https://txtterms.co/88022-2/" target="_blank">txtterms.co/88022-2</a>
          </p>
          <p>
            Contributions to TNC or any of its participating committee are not tax
            deductible and will be used in connection with federal elections. By contributing you understand and acknowledge that the recipient committees may use your contributed funds for any reason, as determined in their sole discretion, consistent with their obligations under federal election law. No statement contained in any solicitation should be reasonably understood or construed as a promise, earmark, or other designation to make any specific use of funds you contribute.
          </p>
          <p>
            Contributions to TNC are subject to federal contribution limits and
            source prohibitions. Contributions from corporations, foreign nationals
            (i.e., without "green cards"), federal government contractors, and other
            federally impermissible sources are prohibited. Contributions made in
            the name of, or refunded by, any other person are prohibited by law.
          </p>
          <p>
            Contributions to TNC from individuals (multicandidate PACs) will be
            allocated according to the following formula:
          </p>
          <p>
            1. 90% to DJTFP, which will designate the funds first to its primary
            election account, then to its general election account, and finally to
            its recount account, up to a maximum of $3,300 ($5,000) per election;
            and
          </p>
          <p>2. 10% to the RNC, up to a maximum of $413,000 ($150,000) per year.</p>
          <p>
            Any contribution exceeding the legal amount that may be contributed to
            any committee will be distributed to the remaining participating
            committee to the extent permitted by federal law and according to the
            allocation method set forth above. Any contribution that would cause a
            contributor to exceed any applicable federal contribution limit or
            otherwise not be permissible under FECA and FEC regulations will be
            refunded to the contributor. Contributors may specify a different
            allocation than the one set forth above.
          </p>
          <p>
            Federal law requires us to use our best efforts to collect and report
            the name, mailing address, occupation, and name of employer of each
            individual whose aggregate contributions exceed $200 in an election
            cycle or calendar year, as applicable.
          </p>
          <p className="pf">
            Paid for by Trump National Committee JFC, a joint fundraising committee
            of Donald J. Trump for President 2024, Inc. and the Republican National
            Committee.
          </p>
        </div>
      </div>
    </div>
    
    // </div>
  );
};
