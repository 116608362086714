import axios from 'axios';


export const FETCH_DATA_REQUEST = 'ETH/FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'ETH/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'ETH/FETCH_DATA_FAILURE';


export const fetchEthPriceRequest = () => ({
    type: FETCH_DATA_REQUEST,
  });
  
  export const fetchEthPriceSuccess = (data) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
  });
  
  export const fetchEthPriceFailure = (error) => ({
    type: FETCH_DATA_FAILURE,
    payload: error,
  });
  
  export const fetchEthPrice = () => {
    return (dispatch) => {
      dispatch(fetchEthPriceRequest());
      axios.get('https://www.binance.com/api/v3/ticker/price?symbol=ETHUSDT')
        .then(response => {
          dispatch(fetchEthPriceSuccess(response.data));
        })
        .catch(error => {
          dispatch(fetchEthPriceFailure(error.message));
        });
    };
  };
  
export const selectEthPriceState = state => state.ethPrice?.ethPrice||0;


const initialState = {
    loading: false,
    ethPrice: 0,
    error: '',
};

const ethPriceProducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DATA_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_DATA_SUCCESS:
        return {
          loading: false,
          ethPrice: action.payload.price,
          error: '',
        };
      case FETCH_DATA_FAILURE:
        return {
          loading: false,
          ethPrice: 0,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ethPriceProducer;
  
  