import { useSelector, useDispatch } from 'react-redux';


import { showPopup, hidePopup, selectPopupState } from '../ducks/popupDuck';

// Custom hook to retrieve the current value
export const useIsVisablePopup = () => {
    return useSelector(selectPopupState);
  };

// Custom hook 
export const useShowPopup = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(showPopup());
    };
};

export const useHidePopup = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(hidePopup());
    };
};


