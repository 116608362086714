// src/FormComponent.js
import React from 'react';
import FullScreenPopup from '../fullscreen-popup/FullScreenPopup';
import { useShowPopup } from '../../utils/popupUtils';

const Form = () => {
    const showPopup = useShowPopup()

    return (
        <div className="container form-container">
            <form>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="form-control" id="firstName" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" className="form-control" id="lastName" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control" id="email" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="mobile">Mobile</label>
                        <input type="tel" className="form-control" id="mobile" />
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="address1">Address Line 1</label>
                    <input type="text" className="form-control" id="address1" />
                </div>

                <div className="mb-3">
                    <label htmlFor="address2">Address Line 2</label>
                    <input type="text" className="form-control" id="address2" />
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="city">City</label>
                        <input type="text" className="form-control" id="city" />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="zip">Zip</label>
                        <input type="text" className="form-control" id="zip" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="state">State</label>
                        <select className="form-control" id="state">
                            <option value="">Choose...</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            {/* Add other states here */}
                        </select>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="occupation">Occupation</label>
                        <input type="text" className="form-control" id="occupation" />
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="employer">Employer</label>
                    <input type="text" className="form-control" id="employer" />
                </div>

                <div className='d-flex align-items-center justify-content-center gap-3 mt-2  '>

                    <button type="submit" className="btn btn-danger btn-block mt-4" onClick={showPopup}>Donate Now</button>
                    {/* <button type="submit" className="btn btn-danger btn-block mt-4">Donate Max Now</button> */}
                </div>

            </form>
            <FullScreenPopup>
            </FullScreenPopup>

        </div>
    );
};

export default Form;
