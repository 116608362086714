import { combineReducers } from 'redux';
import languageReducer from './ducks/languageDuck';
import bnbPriceProducer from './ducks/bnbPriceDuck';
import ethPriceProducer from './ducks/ethPriceDuck';
import donateReducer from './ducks/donatePackageDuck';
import popupReducer from './ducks/popupDuck';
const rootReducer = combineReducers({
  language: languageReducer,
  bnbPrice: bnbPriceProducer,
  ethPrice: ethPriceProducer,
  donate: donateReducer,
  fullscreenPopup: popupReducer
  // Add other reducers here if needed
});

export default rootReducer;
